<template>
  <v-container id="schedule-tables" fluid tag="section">
    <v-col class="text-right">last update at {{ timestamp }}</v-col>
    <v-col class="text-right">
      <!-- [begin] dialog for search lbkey and add schedule -->
      <v-dialog v-model="dialog_form" max-width="60%" @click:outside="close">
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="3">
                  新增土地情資：{{ editedItem.scheduleDetail.land_new_info }}
                </v-col>
                <v-col cols="3">
                  調閱土地情資：{{
                    editedItem.scheduleDetail.land_new_info_has_tp
                  }}
                </v-col>
                <v-col cols="3">
                  新增建物情資：{{ editedItem.scheduleDetail.build_new_info }}
                </v-col>
                <v-col cols="3">
                  調閱建物情資：{{
                    editedItem.scheduleDetail.build_new_info_has_tp
                  }}
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="3">
                  土地謄本調閱：{{ editedItem.scheduleDetail.land_has_tp }}
                </v-col>
                <v-col cols="3">
                  土地謄本金額：{{ editedItem.scheduleDetail.land_has_tp * 10 }}
                </v-col>
                <v-col cols="3">
                  建物謄本調閱：{{ editedItem.scheduleDetail.build_has_tp }}
                </v-col>
                <v-col cols="3">
                  建物謄本金額：{{
                    editedItem.scheduleDetail.build_has_tp * 10
                  }}
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="9"> </v-col>
                <v-col cols="3">
                  合計謄本金額：{{
                    (editedItem.scheduleDetail.land_has_tp +
                      editedItem.scheduleDetail.build_has_tp) *
                    10
                  }}
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!--[end] dialog for search lbkey and add schedule -->
    </v-col>
    <v-col cols="12" lg="12">
      <base-material-chart-card
        :data="hourSpiderWork.data"
        :options="hourSpiderWork.options"
        :responsive-options="hourSpiderWork.responsiveOptions"
        color="#E91E63"
        hover-reveal
        type="Bar"
      >
        <template v-slot:reveal-actions>
          <v-tooltip bottom>
            <template v-slot:activator="{ attrs, on }">
              <v-btn v-bind="attrs" color="info" icon v-on="on">
                <v-icon color="info"> mdi-refresh </v-icon>
              </v-btn>
            </template>

            <span>Refresh</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ attrs, on }">
              <v-btn v-bind="attrs" light icon v-on="on">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>

            <span>Change Date</span>
          </v-tooltip>
        </template>

        <h4 class="card-title font-weight-light mt-2 ml-2">
          Spider worked amount(per hour)
        </h4>
      </base-material-chart-card>
    </v-col>
    <base-material-card
      icon="mdi-clipboard-text"
      title="schedule"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        multi-sort
      >
        <!-- <template v-slot:expanded-item="{ item }">
          <td :colspan="9">
            <mission-table :schedule_id="item.id"></mission-table>
            ScheduleDetail
          </td>
        </template> -->
        <template v-slot:item.cdr="{ item }">
          {{ item.cdr }}
        </template>
        <template v-slot:item.total="{ item }">
          {{ item.total }}
        </template>
        <template v-slot:item.todo="{ item }">
          {{ item.todo
          }}{{ "(" + ((item.todo / item.total) * 100).toFixed(2) + "%)" }}
        </template>
        <template v-slot:item.fail="{ item }">
          {{ item.fail
          }}{{ "(" + ((item.fail / item.total) * 100).toFixed(2) + "%)" }}
        </template>
        <template v-slot:item.single_list="{ item }">
          {{ item.single_list }}({{ item.epland_task_left_time }})
        </template>
        <template v-slot:item.multi_list="{ item }">
          {{ item.multi_list }}({{ item.quanta_task_left_time }})
        </template>
        <template v-slot:item.add_land_info="{ item }">
          {{ item.add_land_info
          }}{{
            "(" + ((item.add_land_info / item.total) * 100).toFixed(2) + "%)"
          }}
        </template>
        <template v-slot:item.add_build_info="{ item }">
          {{ item.add_build_info }}
        </template>
        <template v-slot:item.land_balance="{ item }">
          {{ item.land_owner_tps * 10 }}
        </template>
        <template v-slot:item.build_balance="{ item }">
          {{ item.building_owner_tps * 10 }}
        </template>
        <template v-slot:item.total_balance="{ item }">
          {{ (item.land_owner_tps + item.building_owner_tps) * 10 }}
        </template>
        <!-- <template v-slot:item.create_time="{ item }">{{
          item.create_time | moment("YYYY-MM-DD HH:mm:ss")
        }}</template>
        <template v-slot:item.update_time="{ item }">{{
          item.update_time | moment("YYYY-MM-DD HH:mm:ss")
        }}</template> -->
        <template v-slot:item.done_rate="{ item }">
          {{ item.mission_done_num + "/" + item.mission_num
          }}{{
            "(" +
            ((item.mission_done_num / item.mission_num) * 100).toFixed(2) +
            "%)"
          }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon small class="mr-2" v-on="on" @click="viewDetail(item)"
                >mdi-eye-outline</v-icon
              >
            </template>
            <span>{{ $t("view") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </base-material-card>
    <!-- <pack-table></pack-table> -->
  </v-container>
</template>
<script>
// import PackTable from "./PackTable";
import { mapState } from "vuex";

export default {
  data: (vm) => ({
    default_system: false,
    footerProps: {
      showFirstLastPage: true,
      showCurrentPage: true,
      firstIcon: "mdi-chevron-double-left",
      lastIcon: "mdi-chevron-double-right",
      prevIcon: "mdi-chevron-left",
      nextIcon: "mdi-chevron-right",
      itemsPerPageOptions: [10, 20, 50],
    },
    editedItem: {
      car: {
        city: "F",
        district: "",
        interval: 7,
      },
      type: 0,
      thread_count: 3,
      scheduleDetail: {
        land_new_info: 0,
        land_new_info_has_tp: 0,
        build_new_info: 0,
        build_new_info_has_tp: 0,
        land_has_tp: 0,
        build_has_tp: 0,
      },
    },
    defaultItem: {
      car: {
        city: "F",
        district: "",
        interval: 7,
      },
      type: 0,
      thread_count: 3,
      scheduleDetail: {
        land_new_info: "",
        land_new_info_has_tp: "",
        build_new_info: "",
        build_new_info_has_tp: "",
        land_has_tp: "",
        build_has_tp: "",
      },
    },
    editedIndex: -1,
    dialog_form: false,
    action: "",
    items: [],
    totalItems: 0,
    loading: false,
    options: {},
    headers: [
      {
        sortable: false,
        text: vm.$i18n.t("id"),
        value: "id",
      },
      {
        sortable: false,
        text: vm.$i18n.t("cdr"),
        value: "cdr",
      },
      {
        sortable: false,
        text: vm.$i18n.t("total"),
        value: "total",
      },
      {
        sortable: false,
        text: vm.$i18n.t("list todo"),
        value: "todo",
      },
      {
        sortable: false,
        text: vm.$i18n.t("list fail"),
        value: "fail",
      },
      {
        sortable: false,
        text: vm.$i18n.t("single list todo(d:h:m:s)"),
        value: "single_list",
      },
      {
        sortable: false,
        text: vm.$i18n.t("multi list todo(d:h:m:s)"),
        value: "multi_list",
      },
      {
        sortable: false,
        text: vm.$i18n.t("land-tp-todo"),
        value: "land_transcript_num",
      },
      {
        sortable: false,
        text: vm.$i18n.t("land-tp-done"),
        value: "land_transcript_done_num",
      },
      {
        sortable: false,
        text: vm.$i18n.t("building-tp-todo"),
        value: "building_transcript_num",
      },
      {
        sortable: false,
        text: vm.$i18n.t("building-tp-done"),
        value: "building_transcript_done_num",
      },
      {
        sortable: false,
        text: vm.$i18n.t("region done rate"),
        value: "done_rate",
      },
      {
        sortable: false,
        text: vm.$i18n.t("actions"),
        value: "actions",
      },
    ],
    lbkeys: [],
    lbkeys_count: 0,
    search_options: {},
    search_headers: [],
    auto_into_pool: true,
    hourSpiderWork: {
      data: {
        labels: [],
        series: [],
      },
      options: {
        axisX: {
          showGrid: false,
        },
        low: 0,
        high: 2000,
        chartPadding: {
          top: 0,
          right: 5,
          bottom: 0,
          left: 0,
        },
      },
      responsiveOptions: [
        [
          "screen and (max-width: 640px)",
          {
            seriesBarDistance: 5,
            axisX: {
              labelInterpolationFnc: function (value) {
                return value[0];
              },
            },
          },
        ],
      ],
    },
    timestamp: "",
    timer: "",
  }),
  components: {
    // PackTable,
  },
  computed: {
    ...mapState(["token"]),
    formTitle() {
      let title = "";
      switch (this.action) {
        case "create":
          title = this.$t("create");
          break;
        case "view":
          title = this.$t("view");
          break;
        case "edit":
          title = this.$t("edit");
          break;
      }
      return title + " " + this.$t("schedule");
    },
  },
  created() {
    // this.timer = setInterval(this.refreshReport(), 1000 * 60 * 5);
  },
  methods: {
    refreshReport() {
      this.getTime();
      this.getScheduleReport();
    },
    getTime() {
      const currentObj = this;
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      const time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date + " " + time;
      currentObj.timestamp = dateTime;
    },
    getScheduleReport() {
      const currentObj = this;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url =
        process.env.VUE_APP_SERVER_URL + "/backend/schedule_report/getReports/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };
      this.axios
        .get(url, config)
        .then(function (response) {
          currentObj.items = response.data["results"];
          currentObj.totalItems = response.data["count"];
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    getSpiderReport() {
      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL + "/backend/schedules/getReports/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          console.log(response);
          currentObj.hourSpiderWork.data.labels =
            response.data["results"]["labels"];
          currentObj.hourSpiderWork.data.series = [];
          currentObj.hourSpiderWork.data.series.push(
            response.data["results"]["series"]
          );
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    setResult(data) {
      const info = JSON.parse(JSON.stringify(data));
      this.lbkeys_count = data.count;
      this.lbkeys = data.results;
    },
    viewDetail(item) {
      this.action = "view";
      console.log("item.id " + item.id);
      this.editedIndex = item.id;
      this.dialog_form = true;
      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/backend/schedule_report/" +
        item.id +
        "/getScheduleDetail/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };
      currentObj.editedItem.scheduleDetail =
        currentObj.defaultItem.scheduleDetail;
      this.axios
        .get(url, config)
        .then(function (response) {
          console.log(response);
          currentObj.editedItem.scheduleDetail = response.data["results"];
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    createItem() {
      this.action = "create";
    },

    close() {
      this.dialog_form = false;
      setTimeout(() => {
        // this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
        // this.region_info = [];
        // this.editedIndex = -1;
      }, 300);
    },
  },
  watch: {
    options: {
      handler() {
        this.getTime();
        this.getSpiderReport();
      },
    },
  },
};
</script>
